import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#282828",
      "fill-rule": "evenodd",
      d: "M6.536 12.528a1 1 0 0 1 0-1.414l3.121-3.12L6.536 4.87A1 1 0 1 1 7.95 3.457l3.828 3.829a1 1 0 0 1 0 1.414L7.95 12.528a1 1 0 0 1-1.414 0"
    }, null, -1)
  ])))
}
export default { render: render }