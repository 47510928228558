import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#282828",
      "fill-rule": "evenodd",
      d: "M9.464 12.528a1 1 0 0 0 0-1.414l-3.121-3.12L9.464 4.87A1 1 0 1 0 8.05 3.457L4.222 7.286a1 1 0 0 0 0 1.414l3.828 3.828a1 1 0 0 0 1.414 0"
    }, null, -1)
  ])))
}
export default { render: render }