<template>
  <div
    class="overflow-hidden relative"
    :class="container ? 'mb-6 md:mb-16' : null"
  >
    <div :class="{ small: small, container }">
      <Swiper
        :modules="modules"
        :navigation="{
          nextEl: sliderNext,
          prevEl: sliderPrev,
        }"
        :slidesPerView="'auto'"
        :spaceBetween="spaceBetween"
        :initialSlide="0"
        :grabCursor="false"
        :watchOverflow="false"
        :watchSlidesProgress="true"
        :observer="true"
        :observeParents="true"
        :loop="loop"
        :grid="{
          rows: mobileRows,
          fill: mobileRows > 1 ? 'row' : 'column',
        }"
        :mousewheel="{
          forceToAxis: true,
        }"
        :autoplay="
          true
            ? false
            : {
                delay: 6000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }
        "
        :speed="500"
        :freeMode="true"
        :breakpoints="{
          640: {
            grid: {
              rows: 1,
            },
            spaceBetween: spaceBetween / 2,
          },
        }"
        :a11y="{
          prevSlideMessage: $t('Prev'),
          nextSlideMessage: $t('Next'),
        }"
        class="!overflow-visible"
        @slideChange="emit('slideChange', $event)"
      >
        <slot />
      </Swiper>
    </div>
    <div
      v-if="fade"
      class="absolute right-0 top-0 h-full w-10 bg-gradient-to-l from-white z-10 hidden md:block"
      :class="!container ? 'pl-10' : ''"
    />
    <div
      v-if="fade"
      class="absolute left-0 top-0 h-full w-10 bg-gradient-to-r from-white z-10 hidden md:block"
      :class="!container ? 'pl-10' : ''"
    />
    <div
      ref="sliderNext"
      slot="button-next"
      class="swiper-button-next bg-white rounded-full border-gray-300 border shadow-lg transition-colors duration-100 hover:bg-gray-300"
    >
      <IconForward />
    </div>
    <div
      ref="sliderPrev"
      slot="button-prev"
      class="swiper-button-prev bg-white rounded-full border-gray-300 border shadow-lg transition-colors duration-100 hover:bg-gray-300 select-none"
    >
      <IconBackward />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Swiper } from 'swiper/vue'
import {
  FreeMode,
  Navigation,
  Mousewheel,
  Autoplay,
  EffectFade,
  Grid,
  A11y,
} from 'swiper/modules'
import 'swiper/css/bundle'

import type ExtendedSwiper from '~/typesManual/swiper'

import IconForward from '~/assets/icons/forward.svg?component'
import IconBackward from '~/assets/icons/backward.svg?component'

const emit = defineEmits<{
  slideChange: [swiper: ExtendedSwiper]
}>()

const modules = [
  Navigation,
  Mousewheel,
  Autoplay,
  EffectFade,
  FreeMode,
  Grid,
  A11y,
]
const sliderNext = ref(null)
const sliderPrev = ref(null)

withDefaults(
  defineProps<{
    container?: boolean
    spaceBetween?: number
    fade?: boolean
    mobileRows?: number
    loop?: boolean
    small?: boolean
  }>(),
  {
    container: true,
    spaceBetween: 30,
    fade: false,
    mobileRows: 1,
    loop: false,
    small: false,
  }
)
</script>

<style lang="scss" scoped>
// Due to the external swiper code injecting html into the DOM, some of the css style is maintained here instead of tailwind.
// the css naming here is based on the classes of the swiper injects. see https://swiperjs.com/swiper-api for details
:deep() {
  .swiper-container {
    @apply overflow-visible;
  }
  .swiper-slide {
    @apply w-[260px] h-auto;

    .small & {
      @apply w-auto;
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    @apply w-12 h-12 hidden;
    &:after {
      @apply hidden;
    }
    @screen md {
      @apply flex;
    }
    &.swiper-button-disabled {
      @apply opacity-0;
    }
    svg {
      width: unset;
      height: unset;
    }
  }
}
</style>
